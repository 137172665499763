import styled from "styled-components"

export const CarouselStyled = styled.div`
  .container {
    position: static;

    p {
      margin: 0;
    }
  }

  .slider-nav-wrapper > div {
    padding: 0;
  }

  .heading {
    h1 {
      font-size: 37px;
      @media (max-width: 470px) {
        font-size: 27px;
        line-height: 40px;
      }
      @media (max-width: 320px) {
        font-size: 24px;
        line-height: 40px;
      }
    }
  }

  .slide-col {
    padding: 0;
    position: relative;
    z-index: 2;
  }

  .slider-image-mobile {
    display: block;
    img {
      width: 100%;
    }
  }

  .slider-image-desktop {
    display: none;
  }

  .carousel-inner {
    position: initial;
    overflow: visible;

    .carousel-item {
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      display: block;
      position: absolute;
      transition: opacity 1s ease-in-out;

      &:first-of-type {
        position: relative;
      }
    }

    .active {
      opacity: 1;
    }
  }

  .carousel-fade .carousel-item {
    opacity: 0;
    transition-duration: 0.6s;
    transition-property: opacity;
  }

  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-left,
  .carousel-fade .carousel-item-prev.carousel-item-right {
    opacity: 1;
  }

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    opacity: 0;
  }

  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translateX(0);
    transform: translate3d(0, 0, 0);
  }

  .arrows-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0 10px 0;
    margin-bottom: 5px;
    display: none;

    .arrow {
      width: 20px;
      height: 20px;
      cursor: pointer;
      transform: rotate(45deg);
    }

    .arrow-prev {
      border-bottom: 4px solid #dadada;
      border-left: 4px solid #dadada;
      margin-left: 6px;
    }

    .arrow-next {
      border-top: 4px solid #dadada;
      border-right: 4px solid #dadada;
      margin-right: 6px;

    }
  }

  .hide {
    display: none;
  }

  .info-box-desktop {
    flex-direction: row;
    //border: 1px solid #CBCBCB;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 4px;
    // max-width: 100%;
    width: auto;
    margin: 0 0 10px 0;
    display: inline-flex;

    p {
      padding: 8px 17px;
      display: inline-block;
      margin: 0;
      // width: auto;
      font-size: 16px;
      font-weight: 500;
      border-right: 1px solid #CBCBCB;
      white-space: nowrap;
    }

    p:nth-last-of-type(1) {
      border-right: none;
      //margin: 0 auto;
    }
  }

  .info-box-top {
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    border-bottom: 1px solid #dadada;
    padding-top: 15px;
    margin-bottom: 21px;

    p {
      margin-bottom: 14px;
      min-width: 85px;
    }

    p:nth-of-type(2) {
      opacity: 0.3;
      text-align: right;
    }
  }

  .bottom-description {
    padding-top: 32px;
    position: relative;
    width: 100%;

    h2 {
      font-size: 26px;
      padding-bottom: 17px;
      margin: 0;
    }

    p {
      font-size: 20px;
      font-weight: 300;
      max-width: 500px;

      @media (max-width: 1200px) {
        max-width: none;
        width: 100%;
        padding-right: 25px;
      }
    }
  }

  .bottom-description-text {
    min-height: 8em;
  }

  .button-section {
    padding: 50px 0 80px 0;
    z-index: 2;
    position: relative;

    button:nth-of-type(1) {
      margin-bottom: 14px;

      //max-width: 100%;
      //width: 100%;
    }
  }

  .button-call {
    width: 100%;
    button:nth-of-type(1) {
      max-width: 100%;
      width: 100%;
    }
  }

  .slider-nav-wrapper {
    padding-top: 40px;
  }

  .nav-link {
    font-size: 20px;
    border-bottom: 1px solid #dadada;
    position: relative;
    padding: 20px 0;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    a.nav-link-more {
      color: #000;
      width: 100%;

      &:hover, &:focus, &:active {
        text-decoration: none;
      }
    }

    p {
      font-size: 20px;
      font-weight: 300;
      strong {
        font-weight: 500;
      }
      hr {
        height: 0.5em;
        border: none;
        display: block;
        margin: 0;
      }
    }

    div {
      display: flex;
      align-items: center;
      @media (max-width: 1200px) {
        flex-direction: column;
      }
    }

    &:after {
      position: absolute;
      right: 8px;
      top: 47%;
      transform: translateY(-50%);
      content: "";
      width: 10px;
      height: 10px;
      border-top: 2px solid #94c835;
      border-right: 2px solid #94c835;
      transform: rotate(45deg);
    }

    .icon {
      width: 40px;

      img {
        height: 25px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .carousel {
    // height: 100vh;
    //height: 100vh;
    // height: 750px;
    display: flex;
    align-items: center;
    height: calc(100vh - 155px);
    @media (min-width: 2560px) {
      // height: calc(100vh - 430px);
      height: 1009px;
      //height: 100vh;
    }
    @media (max-height: 780px) {
      // height: 750px;
    }
  }

  .carousel-item {
    position: absolute !important;
    height: 100%;
    display: flex !important;
    //align-items: center!important;
    &-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;

      > :first-child {
        margin-bottom: auto;
      }

      > :last-child {
        margin-top: auto;
      }
    }
  }

  .heading {
    display: flex;
    flex-direction: column;

    h1 {
      display: inline-block;
      background: rgba(255, 255, 255, 0.75);
      border-radius: 4px;
      padding: 0.12em 0.2em;
      //border: 1px solid #cecece;
      @media (min-width: 1440px) {
        font-size: 48px !important;
      }
    }
  }

  .nav-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottom-description {
    h2, p {
      font-size: 20px;
    }

    h2 {
      padding-bottom: 2px;
      position: relative;
    }

    .line {
      width: 45%;
      height: 1px;
      margin: 34px 0 25px 0;
      background-color: #94c835;
    }

    p:nth-of-type(2) {
      margin: 0;
    }

    p.more {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: -15px;
    }

    .slider-nav-wrapper {
      position: relative;
      padding: 95px 0;
    }

    .container-fluid {
      max-width: 700px;
      z-index: 99999999999999;
      position: absolute;
      bottom: -35px;
      padding: 0;
      left: 0;
      max-height: 150px;
      background-color: rgba(255, 255, 255, .87);
      border-radius: 4px;

      .row {
        height: 100%;
      }

      .nav-link:nth-of-type(1) {
        border-radius: 4px 0 0 4px;
      }

      .nav-link:nth-of-type(4) {
        border-radius: 0 4px 4px 0;
      }

      .nav-link {
        height: 100%;
        // width: 200px;
        padding: 0;
        border-bottom: none;
        // border-right: 1px solid #E3E3E3;
        box-shadow: 3px 0 10px rgba(0, 0, 0, 0.09);
        font-size: 15px;
        font-weight: 600;
        transition: 150ms;
        border-bottom: 4px solid transparent;

        &:after {
          top: calc(100% - 27px);
          margin-top: -3px;
          // bottom: 45px;
          border-color: #454545;
          right: 20px;
          transition: 300ms;
        }

        &:hover {
          border-bottom: 4px solid #94C835;


          &:after {
            border-color: #94C835;
          }
        }

        div {
          display: block;
          //flex-direction: column;
          //justify-content: flex-start;
          //align-items: flex-start;
          //height: 100%;
        }

        .nav-link-more {
          // background-color: #fff;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 15px 25px 15px;
          width: 100% !important;

          > div:last-child {
            margin-left: auto;
            // width: 100px;
            white-space: nowrap;
            padding-right: 15px;
            transition: 0.2s color;
            margin-top: auto;

          }
          &:hover {
            > div:last-child {
              color: #94C835;
            }
          }
          p {
            // font-weight: 700;
            font-size: 16px;
            line-height: 1.3;

            + p {
              margin-top: 0.5em;
            }

            span {
              display: block;
            }
          }

          .icon {
            padding-bottom: 13px;
            height: auto;

            img {
              height: 35px;
            }
          }


        }
      }
    }
  }

  .slider-image-mobile {
    display: none;
  }

  .slider-image-desktop {
    position: absolute;
    z-index: 1;
    //top: -235px;
    //top: -161px;
    top: -155px;
    right: 0;
    width: 100%;
    display: block;
    overflow: hidden;
    padding: 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    //@media (max-height: 780px) {
    //  top: initial;
    //  bottom: 0;
    //  height: 905px;
    //  right: 0;
    //}
    .nav-button {
      position: absolute;
      right: 30px;
      top: 40px;
    }
  }

  .button-section {
    padding: 35px 0;

    transform: scale(0.85);
    transform-origin: left bottom;

    @media (max-width: 1200px) {
        flex-direction: row;
    }

    div:first-child > a > button {
      max-width: 100%;
      justify-content: center;
    }
  }

  .desktop-buttons {
    // flex-direction: row;

    a:first-child {
      margin-right: 14px;
    }

    @media (max-width: 1440px) {
      // flex-direction: column;
    }
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2394c835' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;

  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2394c835' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  }

  .carousel-indicators li {
    background-color: #94c835;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    opacity: 0.3;
    margin: 5px;
  }

  .carousel-indicators .active {
    background-color: #94c835;
    opacity: 1;
  }

  @media (min-width: 2559px) {
    .carousel-item > div > div.container {
      //position: relative;
    }

    .slider-image-desktop {
      top: -157px;
      bottom: -178px;
      height: 1165px;
      //right: 30px;
    }
  }

`
