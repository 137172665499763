import styled from "styled-components"
import bg from "../../images/bg-firm.jpg"
export const AboutStyled = styled.div`
  font-family: "futura-pt", sans-serif !important;
  padding: 46px 0 0 0;
  position: relative;
  .heading {
    padding: 0 0 84px 0;
    h1 {
      font-size: 48px;
      font-weight: 500;
      padding-bottom: 32px;
    }
    p {
      font-size: 20px;
      font-weight: 300;
      margin: 0;
      padding: 0;
    }
    p:nth-of-type(1) {
      font-weight: 500;
      padding-bottom: 24px;
    }
  }
  .buttons-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 46px 0 80px 0;
    a:first-child button {
      margin-bottom: 15px;
    }
    a:first-child button span{
      //padding: 0 32px;
    }
  }
  @media (max-width: 1200px) {
    .info-table {
      display: none;
    }
    .tiles-desktop {
      display: none;
    }
  }
  @media (min-width: 1400px) {
    .info-table {
    }
  }
  @media (min-width: 1200px) {
    padding: 0;
    .heading-parent {
      padding-right: 80px;
      padding-left: 0;
      //padding-left: 100px;
    }
    .heading {
      padding-top: 100px;
      p {
        margin: 0;
        padding: 0;
      }
    }
    .col-img {
      position: initial !important;
      height: 100%;
    }
    .tiles-desktop {
      display: flex;
      flex-direction: column;
      .single-tile:nth-last-of-type(1) {
        padding: 0;
      }
      .single-tile {
        display: flex;
        flex-direction: row;
        padding-bottom: 15px;

        .icon {
          img {
            height: 80px;
          }
        }
        .content {
          padding-left: 60px;
          font-size: 20px;
          p {
            font-size: 16px;
          }
          p span {
            font-weight: 500;
          }
          p:nth-of-type(1) {
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 8px;
          }
          p:nth-of-type(2) {
            font-weight: 300;
            font-size: 20px;
          }
        }
      }
    }
    .info-table {
      display: flex;
      flex-direction: row;
      border: 1px solid #dadada;
      height: 140px;
      margin: 65px 0 100px 0;
      border-radius: 4px;
      .single-data {
        width: 300px;
        border-right: 1px solid #dadada;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          display: flex;
          flex-direction: column;
        }
        span {
          font-weight: 500;
        }
        span:nth-of-type(1) {
          font-size: 40px;
          margin-left: -5px;
          margin-bottom: -5px;
        }
        span:nth-of-type(2) {
          font-size: 20px;
          font-weight: 300;
        }
      }
      .single-data:nth-last-of-type(1) {
        border-right: none;
      }
    }
    .img-background {
      background-image: url(${bg});
      background-size: cover;
      height: 100%;
      width: 50%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

`
