import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Tiles from "../shared/tiles/tiles"
import user from "../../images/user.png"
import home from "../../images/home.png"
import homegreen from "../../images/home-green.png"
import { AboutStyled } from "./aboutStyle"
import { EmailButton, PhoneButton } from "../shared/button/button"
import { injectIntl } from "gatsby-plugin-intl"

const About = ({ intl }) => {
  const tilesContent = [
    {
      title: intl.formatMessage({ id: "home.company.client.title" }),
      content: (
        <p>
          {intl.formatMessage({ id: "home.company.client.content.front" })}{" "}
          <span>
            {intl.formatMessage({ id: "home.company.client.content.bold" })}
          </span>{" "}
          {intl.formatMessage({ id: "home.company.client.content.back" })}
        </p>
      ),
      icon: user,
    },
    {
      title: intl.formatMessage({ id: "home.company.experience.title" }),
      content: (
        <p>
          {intl.formatMessage({ id: "home.company.experience.content.front" })}<span> {intl.formatMessage({ id: "home.company.experience.content.bold" })}</span> {intl.formatMessage({ id: "home.company.experience.content.back" })}
        </p>
      ),
      icon: homegreen,
    },
    {
      title: intl.formatMessage({ id: "home.company.capital.title" }),
      content: (
        <p>
          {intl.formatMessage({ id: "home.company.capital.content.front" })} <span>{intl.formatMessage({ id: "home.company.capital.content.bold" })}</span>, {intl.formatMessage({ id: "home.company.capital.content.back" })}
        </p>
      ),
      icon: user,
    },
  ]
  return (
    <AboutStyled>
      <Container className={"h-100"}>
        <Row className={"h-100"}>
          <Col xs={12} lg={6} className={"heading-parent"}>
            <div className="heading">
              <h1>{intl.formatMessage({ id: "home.company.title" })}</h1>
              <p>{intl.formatMessage({ id: "home.company.content.bold" })}</p>
              <p>{intl.formatMessage({ id: "home.company.content" })}</p>
            </div>
            <div className="tiles-desktop">

            <div className="single-tile">
              <div className="icon">
                <img src={user} alt="" />
              </div>
              <div className="content">
                <p>{intl.formatMessage({ id: "about.info.client" })}</p>
                <p>
                  {intl.formatMessage({ id: "about.info.client.content.front" })}
                   <span> {intl.formatMessage({ id: "about.info.client.content.bold" })} </span>
                  {intl.formatMessage({ id: "about.info.client.content.back" })}
                </p>
              </div>
            </div>
            <div className="single-tile">
              <div className="icon">
                <img src={home} alt="" />
              </div>
              <div className="content">
                <p>{intl.formatMessage({ id: "about.info.experience" })}</p>
                <p>
                  {intl.formatMessage({ id: "about.info.experience.content.front" })}
                   <span> {intl.formatMessage({ id: "about.info.experience.content.bold" })}</span>
                  {" "}
                </p>
              </div>
            </div>
            </div>
            <div className="info-table">
              <div className="single-data">
                <div>
                  <span>100%</span>
                  <span>{intl.formatMessage({ id: "home.company.tab.asset.content" })}</span>
                </div>
              </div>
              <div className="single-data">
                <div>
                  <span>100%</span>
                  <span>{intl.formatMessage({ id: "home.company.tab.experience.content" })}</span>
                </div>
              </div>
              <div className="single-data">
                <div>
                  <span>100%</span>
                  <span>{intl.formatMessage({ id: "home.company.tab.inovative.content" })}</span>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} className={"d-none d-lg-block col-img"}>
            <div className="img-background" />
          </Col>
          <Col lg={6} className={"d-none d-lg-block tiles-desktop"}></Col>
        </Row>
      </Container>
      <div className="d-xl-none">
        <Tiles tilesContent={tilesContent} />
      </div>
      <div className="buttons-section d-xl-none">
        <PhoneButton />
        <EmailButton />
      </div>
    </AboutStyled>
  )
}

export default injectIntl(About)
