import React from "react"
import Layout from "../components/layout"
import Slider from "../components/slider/slider"
import Investments from "../components/investements/investments"
import About from "../components/about/about"
import Helmet from "react-helmet"

import "reactjs-popup/dist/index.css"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>WILLBUD | Strona Główna</title>
        <meta
          name="description"
          content="WILLBUD jest deweloperem realizującym inwestycje na terenie Trójmiasta i okolic. Oferuje domy, mieszkania oraz lokale usługowe na terenie Gdyni oraz Rumi, wraz z wykończeniem pod klucz. Wyróżnia nas indywidualne podejście klienta oraz wysoka jakość wykończenia."
        />
      </Helmet>
      <Slider />
      <Investments />
      <About />

      {/*      <Modal />*/}
    </Layout>
  )
}

export default IndexPage
