import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { CarouselStyled } from "./sliderStyle"
import slideOJP2 from "@/images/z-8/z-8g.jpg"
import slidePuck from "@/images/puck/z16-2-main-slide.jpg"
import ojp3Image from "@/images/z-20/Janowo_Park_III_ujecie_01.jpg"
import slide2 from "@/images/z-8/OGWII-main.jpg"

import { EmailButton, MobileButton, PhoneButton } from "../shared/button/button"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap"
import { Link } from "gatsby-plugin-intl"
import LanguageContext from "../../context/LanguageContext"
import { injectIntl } from "gatsby-plugin-intl"

const Slider = ({ intl }) => {
  const items = [
    {
      src: ojp3Image,
      altText: "Osiedle Janowo Park III",
      caption: intl.formatMessage({ id: "estate.name.janowoParkTwo" }),
      title: "Osiedle Janowo Park III",
      status: intl.formatMessage({ id: "estate.status.inProgress" }),
      info: ["115 mieszkań", "1 lokal usługowy"],
      // flats:
      //   intl.formatMessage({ id: "offer.janowopark2.info.flats.number" }) +
      //   " " +
      //   intl.formatMessage({ id: "offer.janowopark2.info.flats.name" }),
      m2: "27 - 65",
      place: "Rumia",
      contentBold:
        "Zamieszkaj na doskonale skomunikowanym osiedlu na granicy Rumi i Gdyni",
      mainContent:
        "Osiedle Janowo Park III powstało z myślą o spełnieniu oczekiwań nawet najbardziej wymagających klientów. Nasze wieloletnie doświadczenie pozwoliło stworzyć miejsce, które łączy nowoczesność z harmonią otaczającej natury",
      link: "/oferta/osiedle-janowo-park-3",
    },
    {
      src: slidePuck,
      altText: "Osiedle Pogodne w Pucku",
      caption: intl.formatMessage({ id: "estate.name.janowoParkTwo" }),
      title: "Osiedle Pogodne w Pucku",
      status: intl.formatMessage({ id: "estate.status.inProgress" }),
      info: ["43 mieszkania", "1 lokal usługowy"],
      // flats:
      //   intl.formatMessage({ id: "offer.janowopark2.info.flats.number" }) +
      //   " " +
      //   intl.formatMessage({ id: "offer.janowopark2.info.flats.name" }),
      m2: "30 - 75",
      place: "Puck",
      contentBold: "Twórz swoją przyszłość nad morzem",
      mainContent:
        "Osiedle Pogodne to komfort współgrający z otaczającą przyrodą. Kameralna zabudowa doskonale wkomponowana w strukturę nadmorskiego Pucka to gwarancja spokoju i wewnętrznego balansu pomiędzy nowoczesnym stylem życia a kojącą bliskością natury.",
      link: "/oferta/osiedle-pogodne-w-pucku/",
    },
    {
      src: slide2,
      altText: intl.formatMessage({ id: "estate.name.wiczlinotwo" }),
      caption: intl.formatMessage({ id: "estate.name.wiczlinotwo" }),
      title: intl.formatMessage({ id: "estate.name.wiczlinotwo" }),
      status: intl.formatMessage({ id: "estate.status.inProgress" }),
      info: ["23 budynki", "46 lokali"],
      flats:
        intl.formatMessage({ id: "offer.wiczlinotwo.info.flats.number" }) +
        " " +
        intl.formatMessage({ id: "offer.wiczlinotwo.info.flats.name" }),
      m2: "139 - 160,5",
      place: "Gdynia",
      contentBold: "Tu znajdziesz swój wymarzony dom",
      mainContent: `Kameralne Osiedle domów jednorodzinnych dwulokalowych zostało zaprojektowane dla najbardziej wymagających Klientów. <hr>
Wysokiej jakości materiały użyte podczas budowy, przestronne wnętrza, panoramiczne okna i prywatne ogrody to gwarancja komfortu i jakości.`,
      link: "/oferta/osiedle-gdynia-wiczlino-2/",
    },
    {
      src: slideOJP2,
      altText: intl.formatMessage({ id: "estate.name.janowoParkTwo" }),
      caption: intl.formatMessage({ id: "estate.name.janowoParkTwo" }),
      title: intl.formatMessage({ id: "estate.name.janowoParkTwo" }),
      status: intl.formatMessage({ id: "estate.status.completed" }),
      info: ["79 mieszkań", "4 apartamenty", "3 lokale usługowe"],
      flats:
        intl.formatMessage({ id: "offer.janowopark2.info.flats.number" }) +
        " " +
        intl.formatMessage({ id: "offer.janowopark2.info.flats.name" }),
      m2: "20 - 95",
      place: "Rumia",
      contentBold: intl.formatMessage({ id: "offer.janowopark2.home.bold" }),
      mainContent: intl.formatMessage({ id: "offer.janowopark2.home.main" }),
      link: "/oferta/osiedle-janowo-park-2/",
    },

    // {
    //   src: slide1,
    //   altText: "Slide 1",
    //   caption: "Slide 1",
    //   title: intl.formatMessage({ id: "estate.name.janowoPark" }),
    //   status: intl.formatMessage({ id: "estate.status.done" }),
    //   info: ["10 budynków", "186 mieszkań"],
    //   flats: intl.formatMessage({ id: "home.main.flats" }),
    //   m2: intl.formatMessage({ id: "home.main.space" }),
    //   contentBold: intl.formatMessage({ id: "home.main.content.bold" }),
    //   mainContent: intl.formatMessage({ id: "home.main.content" }),
    //   link: "/oferta/osiedle-janowo-park/",
    // },
  ]
  const [activeIndex, setActiveIndex] = useState(0)
  let animating = false

  const OnExiting = () => {
    animating = true
  }
  const OnExited = () => {
    animating = false
  }
  const Next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }
  const Previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }
  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }
  const Slides = () => {
    return items.map(item => (
      <CarouselItem onExiting={OnExiting} onExited={OnExited} key={item.src}>
        <div className={"w-100 carousel-item-wrapper"}>
          <Container>
            <Row>
              <Col xs={12} className={"slide-col"}>
                <div className="heading">
                  <div>
                    <h1>{item.title}</h1>
                  </div>
                  <div>
                    <div className="info-box-top d-xl-none">
                      <p>
                        <span className="mr-5">{item.place}</span>
                        <br className="d-sm-none" />
                        <span className="mr-5 text-nowrap">{item.status}</span>
                      </p>

                      <p>{item.info.join(", ")}</p>
                    </div>
                  </div>
                  <div>
                    <div className="d-none d-lg-inline-flex info-box-desktop">
                      <p>{item.status}</p>
                      {item.info.map(info => (
                        <p>{info}</p>
                      ))}
                      <p>
                        {item.m2}m<sup>2</sup>
                      </p>
                      <p>{item.place}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Link to={item.link} className={"slider-image-mobile"}>
            <img src={item.src} alt={item.title} />
          </Link>
          <Container>
            <Row>
              {/*<div className="arrows-container">
                <div className="arrow arrow-prev" onClick={Previous}>
                  {" "}
                </div>
                <div className="arrow arrow-next" onClick={Next}>
                  {" "}
                </div>
              </div>*/}
              <div className="bottom-description" style={{ marginTop: "auto" }}>
                <div className={"slider-nav-wrapper"}>
                  <Container fluid>
                    <Row>
                      <Col xs={12} className={"nav-link"}>
                        <Link
                          to={item.link}
                          className={"nav-link-more"}
                          state={{ position: "description" }}
                        >
                          <div>
                            <p>
                              <strong>{item.contentBold}</strong>
                            </p>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.mainContent,
                              }}
                            />
                          </div>
                          <div className={"d-none d-lg-block"}>
                            Zobacz inwestycję
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/*<div className="bottom-description-text">
                  <h2>{item.contentBold}</h2>
                  <p>{item.mainContent}</p>
                </div>
                <p></p>
                <div className="line"></div>
                <p className={"d-none d-lg-block more"}>
                  {intl.formatMessage({ id: "home.main.more.title" })}
                </p>*/}
                {/*<div className={"slider-nav-wrapper"}>
                  <Container fluid>
                    <Row>
                      <Col xs={12} lg={3} className={"nav-link"}>
                        <Link
                          to={item.link}
                          className={"nav-link-more"}
                          state={{ position: "description" }}
                        >
                          <div>
                            <div className="icon">
                              <img src={icon1} alt="" />
                            </div>
                            <p>
                              <span>
                                {intl.formatMessage({
                                  id: "home.main.more.description.title",
                                })}
                              </span>{" "}
                              {intl.formatMessage({
                                id: "home.main.more.description.subtitle",
                              })}
                            </p>
                          </div>
                        </Link>
                      </Col>
                      <Col xs={12} lg={3} className={"nav-link"}>
                        <Link
                          to={item.link}
                          className={"nav-link-more"}
                          state={{ position: "visual" }}
                        >
                          <div>
                            <div className="icon">
                              <img src={icon2} alt="" />
                            </div>
                            <p>
                              <span>
                                {intl.formatMessage({
                                  id: "home.main.more.visual.title",
                                })}{" "}
                              </span>{" "}
                              {intl.formatMessage({
                                id: "home.main.more.visual.subtitle",
                              })}
                            </p>
                          </div>
                        </Link>
                      </Col>
                      <Col xs={12} lg={3} className={"nav-link"}>
                        <Link
                          to={item.link}
                          className={"nav-link-more"}
                          state={{ position: "flats" }}
                        >
                          <div>
                            <div className="icon">
                              <img src={icon3} alt="" />
                            </div>
                            <p>
                              <span>
                                {intl.formatMessage({
                                  id: "home.main.more.flats.title",
                                })}{" "}
                              </span>
                              {intl.formatMessage({
                                id: "home.main.more.flats.subtitle",
                              })}
                            </p>
                          </div>
                        </Link>
                      </Col>
                      <Col
                        xs={12}
                        lg={3}
                        className={"nav-link d-none d-lg-flex"}
                      >
                        <Link
                          to={item.link}
                          className={"nav-link-more"}
                          state={{ position: "localization" }}
                        >
                          <div>
                            <div className="icon">
                              <img src={icon4} alt="" />
                            </div>
                            <p>
                              <span>
                                {intl.formatMessage({
                                  id: "home.main.more.localization.title",
                                })}{" "}
                              </span>{" "}
                              {intl.formatMessage({
                                id: "home.main.more.localization.subtitle",
                              })}
                            </p>
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </Container>
                </div>*/}
              </div>
              <Col xs={12} className={"button-section d-flex"}>
                <div className="d-xl-none button-call">
                  <PhoneButton
                    noIcon={true}
                    bg={"#e7f3d2"}
                    color={"#94C835"}
                    innerText={intl.formatMessage({
                      id: "home.main.more.call",
                    })}
                  />
                  <Link to={item.link}>
                    <MobileButton
                      bg={"#94C835"}
                      innerText={intl.formatMessage({
                        id: "home.main.more.seeOffer",
                      })}
                      color={"#fff"}
                    />
                  </Link>
                </div>
                <div className="d-none d-lg-flex desktop-buttons">
                  <PhoneButton />
                  <EmailButton />
                </div>
              </Col>
              <Col
                lg={12}
                className={"slider-image-desktop "}
                style={{ backgroundImage: `url(${item.src})` }}
              >
                {/*<div className="nav-button">*/}
                {/*  <NavbarButtton />*/}
                {/*  <LanguageDropdownButton theme={"light"} />*/}
                {/*</div>*/}
              </Col>
            </Row>
          </Container>
        </div>
      </CarouselItem>
    ))
  }
  return (
    <LanguageContext.Consumer>
      {language => (
        <CarouselStyled>
          {/*<Navigation/>*/}
          <Carousel
            axis="vertical"
            activeIndex={activeIndex}
            next={Next}
            previous={Previous}
            interval={25000}
            keyboard={false}
            pause={false}
            ride="carousel"
            className="carousel-fade"
          >
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {Slides(language)}
            <div className="slidernav" style={{ display: "block" }}>
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={Previous}
              />

              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={Next}
              />
            </div>
          </Carousel>
        </CarouselStyled>
      )}
    </LanguageContext.Consumer>
  )
}

export default injectIntl(Slider)
